const PRIVATE = 'private';
const PROJECT = 'project';
const PUBLIC = 'public';

export {
  PRIVATE,
  PROJECT,
  PUBLIC,
};

